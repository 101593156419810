.card {
  width: 100%;
  max-width: 30rem;
  margin-top: 30px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.submitBtnContainer {
  display: flex;
  justify-content: center;
}

.loginTableContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
